.container {
  display: flex;
  justify-content: center;
  gap: 2rem;
  width: 100%;
  margin-top: 3rem;
}

.sub-container {
  width: 40%;
  height: auto;
  border: 1px solid;
  border-radius: 15px;
  padding: 24px 36px;
}

#sociallinks {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: 100vh;
}
